// 日期格式化
export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + ''
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
        }
    }
    return fmt
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length)
}
// 使用

{/* <div>{{ item.creat_time | formatDate }}</div> */}
{/* <script>
 import { formatDate } from "../../static/js/formatDate";
  export default {

    filters: {
     formatDate(time) {
       time = time * 1000;
        let date = new Date(time);
       return formatDate(date, "yyyy-MM-dd hh:mm");
      },
      </script> */}